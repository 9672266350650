import { Link } from 'gatsby'
import React from 'react'
import { Images } from '~data'
import { Col, Container, Row } from 'react-bootstrap'
import PortfolioCard from './style'
import Card from './Components/Card'
export default function ProtfolioGridSection() {
  return (
    <PortfolioCard>
      <Container>
        <Row className="align-items-center justify-content-center">
          {/* <Col className="col-xl-7 col-lg-5 col-md-8 col-xs-9 text-center">
       
        <PortfolioCard.Box mb="30px" mbLG="50px">
          <PortfolioCard.Subtitle fontColor="#fd346e" as="h6">Portfolio</PortfolioCard.Subtitle>
          <PortfolioCard.Title as="h2">Realted Works</PortfolioCard.Title>
        </PortfolioCard.Box>
      
      </Col> */}
        </Row>
        <Row className="justify-content-center">
          <Col xs="12" className="col-lg-4 col-md-6 col-xs-6">
            <Card image={Images.DigiTalagency.portFolioImg4} title="Citizen Care 365/24/7" text="Atención al cliente en ciudadanía siempre contestamos, atención 24/7"  to="#" />
          </Col>

          <Col xs="12" className="col-lg-4 col-md-6 col-xs-6">
            <Card image={Images.DigiTalagency.portFolioImg3} title="Apolo" text="" to="https://apolo360.app/"/>
          </Col>

          <Col xs="12" className="col-lg-4 col-md-6 col-xs-6">
            <Card image={Images.DigiTalagency.portFolioImg2} title="Citi Build" text="" to="https://citi.build/" />
          </Col>

          <Col xs="12" className="col-lg-4 col-md-6 col-xs-6">
            <Card image={Images.DigiTalagency.portFolioImg5} title="Cyclops" text="Solución integrada de seguridad inteligente para aereopuertos." to="#" />
          </Col>

          <Col xs="12" className="col-lg-4 col-md-6 col-xs-6">
            <Card image={Images.DigiTalagency.portFolioImg6} title="Digital Identy" text="" to="#" />
          </Col>

          <Col xs="12" className="col-lg-4 col-md-6 col-xs-6">
            <Card image={Images.DigiTalagency.portFolioImg1} title="Black Hawk" text="Plataforma de vinculación ciudadana con inteligencia al servicio de la población." to="#" />
          </Col>

        </Row>
        {/* <PortfolioCard.ButtonGroup>
          <PortfolioCard.Button as={Link} className="btn btn-primary text-white" to="/">Load More</PortfolioCard.Button>
        </PortfolioCard.ButtonGroup> */}
      </Container>
    </PortfolioCard>

  )
}