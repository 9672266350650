import React from "react"
import { PageWrapper } from "~components/Core"
import BannerOne from "~sections/CiudadesInteligentes/BannerOne"
import FooterOne from "~sections/marketing/FooterOne"
import Grid from "~sections/CiudadesInteligentes/Grid"
import PortfolioGridSection from '~sections/CiudadesInteligentes/portfolio-details/PortfolioDetailsOne/ProtfoliGrid'

const header = {
  headerClasses:"site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true,
  // customLogo: Images.HeaderLogo,
}

export default function CiudadesInteligentes() {
  return (
    <PageWrapper headerConfig={header}>
      <BannerOne />
      {/* <Grid /> */}
      <PortfolioGridSection />
      <FooterOne />
    </PageWrapper>
  )
}
