import React from "react"

export default function BannerOne() {
  return (
    <div className="bannerOne_drones">
      <div>
        <h1>CIUDADES INTELIGENTES</h1>
        <p>Tenemos amplia experiencia en participación con proyectos gubernamentales y desarrollamos soluciones basadas en <br/> el uso de tecnologías adhoc para cada proyecto.</p>
        </div>
    </div>
  )
  
}
