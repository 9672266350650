import React from 'react'
import { Link } from '~components'
import Card from "./style"
export default function PortfolioCard({to,image,title,text,...rest}){
return(
<Card as={Link} to={ to ? to : "/" } {...rest}>
      <img className="w-100 br-15" src={image} alt="" />
      <Card.InnerBlock backgroundColor="#FFFFFF">
        <Card.InnerIcon fontColor="#687379">
            <i className="fas fa-external-link-square-alt" />
        </Card.InnerIcon>
        <Card.Content>
          <Card.Title as="h3" fontColor="#25373F">{title}</Card.Title>
          <Card.Text fontColor="#687379">{text}</Card.Text>
        </Card.Content>
      </Card.InnerBlock>
  </Card>
)
}